<template>
  <div class="edp-reportCoverApp">
    <div
      class="edp-reportCoverApp-out"
      :style="
        (pageHeight ? 'height:' + pageHeight + 'px;' : '') +
        (pageWidth ? 'width:' + pageWidth + 'px;' : '')
      "
      id="reportAppCanvas"
    >
      <report-app-cn
        :info="info"
        :percent="pagePercent"
        v-if="pageTemplate == 1 && appLang == 'cn'"
        @resize="comp1ResizeFn"
      />

      <report-app-en
        :info="info"
        :percent="pagePercent"
        v-if="pageTemplate == 1 && appLang == 'en'"
        @resize="comp1ResizeFn"
      />
    </div>

    <i
      class="el-icon-share report-app-share"
      @click="sharePage"
      v-if="!sharePageLoading"
    ></i>

    <section class="edp-reportCoverApp-loading" v-if="!pageStatus">
      <van-loading size="50px" color="#3E886D" text-color="#3E886D" vertical
        >&nbsp;</van-loading
      >
    </section>

    <van-dialog
      v-model="vanDiaVisible"
      :showConfirmButton="false"
      className="evp-feed-dialog-home"
    >
      <section class="evp-feed-dialog-home-in">
        <label class="evp-feed-dialog-home-label"
          >No data record due to the following conditions:</label
        >

        <div class="evp-feed-dialog-home-content">
          1. You didn't agree to the 'Personal Information Processing Policy' on
          My Workhub.
          <br />
          2. Join JLR after 2024/01/01
        </div>

        <div class="evp-feed-dialog-home-buttom">
          <span @click="backToApp" class="home-buttom-button">Return</span>
        </div>
      </section>
    </van-dialog>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import domtoimage from "dom-to-image";
import { Toast } from "vant";

import reportAppCn from "../components/reportCover/reportAppCn.vue";
import reportAppEn from "../components/reportCover/reportAppEn.vue";
export default {
  components: { reportAppCn, reportAppEn },
  name: "reportCoverApp",
  data() {
    return {
      appLang: "en",
      pageWidth: "",
      pageHeight: "",
      pagePercent: 1,
      pageTemplate: -1,
      info: {},

      pageStatus: false,
      vanDiaVisible: false,

      sharePageLoading: false,
    };
  },

  methods: {
    ...mapActions({
      userInfoApp: "login/userInfoApp",
      reportNew: "reportCover/reportNew",
    }),
    backToApp() {
      window.flutter_inappwebview.callHandler("employee_close_webview");
    },
    async pageInit(thisCdsid) {
      let resData = {};

      let res = await this.reportNew(thisCdsid);

      if (res.data && res.data.code == 0) {
        this.pageStatus = true;

        this.pageTemplate = 1;

        resData = res.data.data;

        if (!resData.cname && !resData.ename) {
          this.vanDiaVisible = true;

          return;
        }

        this.$set(this.info, "thisYear", resData.currentYear); // this year
        this.$set(this.info, "nextYear", resData.nextYear); // next year
        this.$set(this.info, "memberYear", resData.yearInCompany); // work year in company total
        this.$set(
          this.info,
          "memberName",
          this.appLang === "cn"
            ? resData.cname || resData.ename
            : resData.ename || resData.cname
        ); // member name
        this.$set(
          this.info,
          "yearInCompanyPercent",
          resData.yearInCompanyPercent
        ); // work beat others percent
        this.$set(
          this.info,
          "salaryIncreaseStatusPage",
          this.appLang === "cn"
            ? resData.salaryIncreaseStatus || resData.salaryIncreaseStatusEn
            : resData.salaryIncreaseStatusEn || resData.salaryIncreaseStatus
        ); // is money job
        this.$set(
          this.info,
          "memberWork",
          this.appLang === "cn"
            ? resData.changePosition || resData.changePositionEn
            : resData.changePositionEn || resData.changePosition
        ); // rise job name
        this.$set(this.info, "meetingPercent", resData.meetingPercent);
        this.$set(this.info, "travelPercent", resData.travelPercent);
        this.$set(this.info, "workingPercent", resData.workingPercent);
        this.$set(
          this.info,
          "tagPage",
          this.appLang === "cn"
            ? resData.tag || resData.tagEn
            : resData.tagEn || resData.tag
        );
        this.$set(this.info, "meetingNum", resData.meetingNum);
        this.$set(this.info, "meetingPeopleNum", resData.meetingPeopleNum);

        this.$set(
          this.info,
          "memberDay",

          this.appLang === "cn"
            ? resData.latestMeetingDate
              ? this.utils.getTimeFn(resData.latestMeetingDate, "noYearNoTime")
              : ""
            : resData.latestMeetingDate
            ? this.utils.dateFormat(resData.latestMeetingDate)
            : ""
        );

        this.$set(
          this.info,
          "memberTime",
          resData.latestMeetingDate
            ? this.utils.getTimeFn(resData.latestMeetingDate, "time")
            : ""
        );

        this.$set(this.info, "meetingMembers", resData.meetingMembers);
        this.$set(this.info, "travelDays", resData.travelDays);

        this.$set(
          this.info,
          "travelCitysPage",
          this.appLang === "cn"
            ? resData.travelCitys || resData.travelCitysEn
            : resData.travelCitysEn || resData.travelCitys
        );

        this.$set(
          this.info,
          "travelFarCitysPage",
          this.appLang === "cn"
            ? resData.travelFarCitys || resData.travelFarCitysEn
            : resData.travelFarCitysEn || resData.travelFarCitys
        );

        this.$set(this.info, "edcActivityNum", resData.edcActivityNum);
        this.$set(this.info, "favoriteActivity", resData.favoriteActivity);
        this.$set(this.info, "csrNum", resData.csrNum);
        this.$set(this.info, "trainNum", resData.trainNum);
        this.$set(this.info, "interestTopic", resData.interestTopic);
        this.$set(this.info, "position", resData.position);

        this.$set(this.info, "score", resData.score);

        this.$set(
          this.info,
          "carModelPage",
          this.appLang === "cn"
            ? resData.carModel || resData.carModelEn
            : resData.carModelEn || resData.carModel
        );

        this.$set(this.info, "recommendedNum", resData.recommendedNum);

        this.$set(this.info, "dept", resData.dept);

        this.$set(this.info, "surveyDept", resData.surveyDept);

        this.$set(this.info, "headImage", resData.headUrl);
      }
    },
    comp1ResizeFn(data) {
      this.pageHeight = data.height;
      this.pageWidth = data.width;

      if (this.page_platform === "windows") {
        this.pageTemplate = -1;

        setTimeout(() => {
          this.pageTemplate = 1;
        }, 100);
      }
    },
    sizePercent() {
      let windowWidth =
        document.documentElement.clientWidth || document.body.clientWidth;

      this.pagePercent = windowWidth / 750;
    },
    sharePage() {
      let _this = this;

      this.sharePageLoading = true;
      this.pageStatus = false;

      setTimeout(() => {
        var node = document.querySelector("#reportAppCanvas");

        domtoimage.toJpeg(node).then(function (dataUrl) {
          domtoimage
            .toJpeg(node, {
              quality: 1,
              width: _this.pageWidth,
              height: _this.pageHeight,
            })
            .then(function (dataUrl2) {
              let str = dataUrl2.split(",")[1];

              let ssJsbridge = setInterval(() => {
                /* eslint-disable */
                if (
                  window.flutter_inappwebview &&
                  window.hasOwnProperty("flutter_inappwebview")
                ) {
                  clearInterval(ssJsbridge);

                  _this.sharePageLoading = false;
                  _this.pageStatus = true;

                  window.flutter_inappwebview
                    .callHandler("employee_share_wx", { pic: str })
                    .then((result) => {
                      Toast("Share success");
                    });
                }
                /* eslint-enable */
              }, 1000);
            });
        });
      }, 500);
    },
  },
  async mounted() {
    if (window.flutter_inappwebview) {
      window.flutter_inappwebview
        .callHandler("employee_get_ssLang")
        .then((result) => {
          this.appLang =
            result && result.toLowerCase().indexOf("cn") != -1 ? "cn" : "en";
        });
    }

    let res = await this.userInfoApp();

    let thisCdsid =
      res && res.data && res.data.data && res.data.data.cdsid
        ? res.data.data.cdsid
        : "";

    if (thisCdsid) {
      await this.pageInit(thisCdsid);
    } else {
      this.vanDiaVisible = true;
      this.info = {};
      this.pageTemplate = 1;
    }

    this.sizePercent();
    window.addEventListener("resize", this.sizePercent);

    $("body").addClass("bodyXnone");
    $("html").addClass("bodyXnone");
  },
  destroyed() {
    window.removeEventListener("resize", this.sizePercent);
    $("body").removeClass("bodyXnone");
    $("html").removeClass("bodyXnone");
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.evp-feed-dialog-home {
  width: toPad(500);

  .evp-feed-dialog-home-in {
    position: relative;
    width: 100%;
    padding: toPad(40);
  }
  .evp-feed-dialog-home-label {
    display: block;
    width: 100%;
    font-size: toPad(32);
    text-align: center;
  }
  .evp-feed-dialog-home-content {
    width: 100%;
    margin-top: toPad(20);
    font-size: toPad(28);
  }
  .evp-feed-dialog-home-buttom {
    width: 100%;
    margin-top: toPad(40);
    font-size: toPad(28);
    text-align: right;

    .home-buttom-button {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.edp-reportCoverApp {
  position: relative;
  width: 100vw;
  overflow: hidden;

  .edp-reportCoverApp-out {
    position: relative;
    width: 100%;
  }
  .edp-reportCoverApp-loading {
    position: fixed;
    z-index: 10;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(0, 0, 0, 0.2);
    left: 0;
    top: 0;
  }
}

.report-app-share {
  font-size: toPad(40);
  width: toPad(60);
  height: toPad(60);
  border-radius: 100%;
  position: fixed;
  z-index: 3;
  background: #fff;
  color: #555;
  display: flex;
  justify-content: center;
  align-items: center;
  right: toPad(30);
  top: toPad(30);
  cursor: pointer;
  opacity: 0.85;
}
.bodyXnone {
  width: 100vw;
  overflow-x: hidden;
}
</style>
