<template>
  <div
    class="edp-reprtAppCn-content"
    :style="'transform:scale(' + percent + ');'"
    ref="compCover"
  >
    <edpWaterBg
      percent="20%"
      opacity="0.4"
      itemHeight="160px"
      slot="bgContent"
    />

    <!-- 1 -->
    <section class="edp-reprtAppCn-square ers-sq1">
      <img
        src="@/src/assets/common/logo-group-white.png"
        alt=""
        class="ers-sq1-logoImage"
      />

      <div class="edp-reprtAppCn-wordCotent ers-sq1-word1">
        {{ info.memberName }}
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq1-word2">年度报告</div>

      <div class="edp-reprtAppCn-wordCotent ers-sq1-largeYear">
        {{ info.thisYear }}
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq1-word3">
        {{ info.thisYear }} 感恩相伴<br />
        {{ info.nextYear }} 携手前行
      </div>

      <div class="edp-reprtAppCn-button1" @click="moveToOne">
        回顾专属于你的 {{ info.thisYear }}
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq1-word4">
        * 年度报告统计数据截止至2023年12月31日
      </div>

      <div class="edp-reprtAppCn-beginIcon"></div>

      <section class="edp-reprtAppCn-gLine">
        <div class="edp-reprtAppCn-gLine-line"></div>
        <i class="el-icon-caret-bottom"></i>
      </section>
    </section>
    <!-- 1 end -->

    <!-- 2 -->
    <section class="edp-reprtAppCn-square ers-sq2" ref="art1">
      <div class="edp-reprtAppCn-wordCotent ers-sq2-word1">
        你成为JLR大家庭的一员，已有
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq2-word2">
        <span class="ers-sq2-spBg"
          >{{ utils.formatMoney(info.memberYear) }} 天</span
        >

        <div v-if="info.yearInCompanyPercent >= 50">
          超越了<span class="edp-reprtAppCn-wordStrong"
            >{{ info.yearInCompanyPercent || 0 }}%</span
          >的小伙伴
        </div>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq2-word3"
        v-if="info.salaryIncreaseStatusPage"
      >
        <p>{{ info.thisYear }}，共赴时光之约</p>
        <p>披星戴月，步伐坚定</p>
        <div v-html="info.salaryIncreaseStatusPage"></div>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq2-word4"
        v-if="info.memberWork"
      >
        <p>{{ info.thisYear }}，恭喜你“士”在必得</p>

        <p>
          成功升级为<br />
          <span class="edp-reprtAppCn-lightBig edp-reprtAppCn-lightBig-green">{{
            info.memberWork
          }}</span>
        </p>

        <p>每一份付出和努力，都不会被辜负</p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq2-word5"
        v-if="info.meetingPercent || info.travelPercent || info.workingPercent"
      >
        <p>{{ info.thisYear }}，你度过了充实的一年</p>
        <p v-if="info.meetingPercent">
          <span class="edp-reprtAppCn-wordStrong"
            >{{ info.meetingPercent || 0 }}%</span
          >的时间全身心沉浸于会议中
        </p>
        <p v-if="info.travelPercent">
          <span class="edp-reprtAppCn-wordStrong"
            >{{ info.travelPercent || 0 }}%</span
          >的时间奔波于商务行程
        </p>
        <p v-if="info.workingPercent">
          <span class="edp-reprtAppCn-wordStrong"
            >{{ info.workingPercent || 0 }}%</span
          >的时间忙碌于日常工作
        </p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq2-word5" v-if="info.tagPage">
        <p>你是当之无愧的</p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq2-word6" v-if="info.tagPage">
        <span class="edp-reprtAppCn-lightBig">
          {{ info.tagPage }}
        </span>
      </div>

      <section class="edp-reprtAppCn-gLine">
        <div class="edp-reprtAppCn-gLine-line"></div>
        <i class="el-icon-caret-bottom"></i>
      </section>

      <img
        src="@/public/images/app/app/sq-bg1.png"
        alt=""
        class="edp-reprtAppCn-sqBgImage"
      />
    </section>
    <!-- 2 end -->

    <!-- 3 -->
    <section class="edp-reprtAppCn-square ers-sq3" v-if="info.meetingNum">
      <div class="edp-reprtAppCn-wordCotent ers-sq3-word1">
        <p>这一年</p>

        <p v-if="info.meetingNum">
          你参加了<span class="edp-reprtAppCn-wordStrong">{{
            info.meetingNum ? utils.formatMoney(info.meetingNum) : 0
          }}</span
          >场会议
        </p>

        <p v-if="info.meetingPeopleNum">
          和<span class="edp-reprtAppCn-wordStrong">{{
            info.meetingPeopleNum ? utils.formatMoney(info.meetingPeopleNum) : 0
          }}</span
          >人一起头脑风暴
        </p>

        <p v-if="info.memberDay && info.memberTime">
          在<span class="edp-reprtAppCn-wordStrong">{{ info.memberDay }}</span>
          这一天，你火力全开，会议持续至
          <span class="edp-reprtAppCn-wordStrong">{{ info.memberTime }}</span>
        </p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq3-word2"
        v-if="info.meetingMembers"
      >
        <p>今年，你和这些同事一起开会到飞起，</p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq3-word3"
        v-if="info.meetingMembers"
      >
        <span class="edp-reprtAppCn-wordStrong">{{ info.meetingMembers }}</span>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq3-word2"
        v-if="info.meetingMembers"
      >
        <p>“参会达人”身份非你莫属!</p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq3-word4">
        <p>
          <span class="edp-reprtAppCn-lightBig">思想碰撞出智慧的火花</span>
        </p>
        <p>
          <span class="edp-reprtAppCn-lightBig"
            >紧密的沟通协作让难题烟消云散</span
          >
        </p>
      </div>

      <section class="edp-reprtAppCn-gLine">
        <div class="edp-reprtAppCn-gLine-line"></div>
        <i class="el-icon-caret-bottom"></i>
      </section>

      <img
        src="@/public/images/app/app/sq-bg2.png"
        alt=""
        class="edp-reprtAppCn-sqBgImage"
      />
    </section>
    <!-- 3 end -->

    <!-- 4 -->
    <section class="edp-reprtAppCn-square ers-sq4" v-if="info.travelDays">
      <div class="edp-reprtAppCn-wordCotent ers-sq4-word1">
        <p>{{ info.thisYear }}，你摘下了口罩</p>
        <p>
          用热情描摹出<span class="edp-reprtAppCn-wordStrong"
            >{{ info.travelDays }}个</span
          >旅途的日夜
        </p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq4-word2"
        v-if="info.travelCitysPage"
      >
        <p>志在千里，你的足迹遍布</p>
        <p class="edp-reprtAppCn-wordStrong">{{ info.travelCitysPage }}</p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq4-word3"
        v-if="info.travelFarCitysPage"
      >
        <p>梦想航至</p>
        <p class="edp-reprtAppCn-wordStrong">{{ info.travelFarCitysPage }}</p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq4-word4">
        <p>工作与探索相映成趣，收获与惊喜不期而遇</p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq4-word5">
        <span class="edp-reprtAppCn-lightBig"
          ><p>凯歌而行，不以山海为远</p>
          <p>乘势而上，不以日月为限</p></span
        >
      </div>

      <section class="edp-reprtAppCn-gLine">
        <div class="edp-reprtAppCn-gLine-line"></div>
        <i class="el-icon-caret-bottom"></i>
      </section>

      <img
        src="@/public/images/app/app/sq-bg3.png"
        alt=""
        class="edp-reprtAppCn-sqBgImage"
      />
    </section>
    <!-- 4 end -->

    <!-- 5 -->
    <section
      class="edp-reprtAppCn-square ers-sq5"
      v-if="info.edcActivityNum || info.csrNum || info.trainNum"
    >
      <div class="edp-reprtAppCn-wordCotent ers-sq5-word1">
        <p>{{ info.thisYear }}，你参加了</p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq5-word2"
        v-if="info.edcActivityNum"
      >
        <p>
          <span class="edp-reprtAppCn-wordStrong">{{
            info.edcActivityNum || 0
          }}</span
          >场EDC活动
        </p>
        <p v-if="info.favoriteActivity">
          <span class="edp-reprtAppCn-wordStrong">{{
            info.favoriteActivity
          }}</span
          >是你的最爱
        </p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq5-word3" v-if="info.csrNum">
        <img src="@/public/images/app/app/active.png" alt="" />
        <span class="edp-reprtAppCn-wordStrong">{{ info.csrNum }}</span
        >次公益CSR活动
      </div>

      <p v-if="info.csrNum">为你的爱心和善意大大点赞！</p>

      <div class="edp-reprtAppCn-wordCotent ers-sq5-word4" v-if="info.trainNum">
        <p>
          <span class="edp-reprtAppCn-wordStrong">{{ info.trainNum }}场</span
          >培训
        </p>
        <p v-if="info.interestTopic">
          <span class="edp-reprtAppCn-wordStrong">{{ info.interestTopic }}</span
          >类是你感兴趣的主题
        </p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq5-word5"
        v-if="info.position == 1"
      >
        <p>
          同时，感谢作为JLR“<span class="edp-reprtAppCn-wordStrong">内训师</span
          >”的你
        </p>
        <p>高能演讲历历在耳</p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq5-word6">
        <p><span class="edp-reprtAppCn-lightBig">身边有你</span></p>
        <p><span class="edp-reprtAppCn-lightBig">胜过千言万语</span></p>
      </div>

      <section class="edp-reprtAppCn-gLine">
        <div class="edp-reprtAppCn-gLine-line"></div>
        <i class="el-icon-caret-bottom"></i>
      </section>

      <img
        src="@/public/images/app/app/sq-bg4.png"
        alt=""
        class="edp-reprtAppCn-sqBgImage"
      />
    </section>
    <!-- 5 end -->

    <!-- 6 -->
    <section
      class="edp-reprtAppCn-square ers-sq6"
      v-if="
        info.dept ||
        info.surveyDept ||
        info.score ||
        info.carModelPage ||
        info.recommendedNum
      "
    >
      <div class="edp-reprtAppCn-wordCotent ers-sq6-word1">
        <p>{{ info.thisYear }}</p>

        <p v-if="info.score">
          你赢得了<span class="edp-reprtAppCn-wordStrong">{{
            utils.formatMoney(info.score)
          }}</span
          >JLR积分
        </p>

        <p v-if="info.carModelPage">
          作为<span class="edp-reprtAppCn-wordStrong">{{
            info.carModelPage
          }}</span
          >车主的你，一路向阳而行
        </p>

        <p v-if="info.recommendedNum">
          今年，你推荐了<span class="edp-reprtAppCn-wordStrong">{{
            info.recommendedNum || 0
          }}</span
          >位候选人加入JLR大家庭
        </p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq6-word2"
        v-if="info.dept && info.surveyDept"
      >
        <p>
          你的部门<span class="edp-reprtAppCn-wordStrong">{{ info.dept }}</span>
        </p>

        <p>
          积极参与了<span class="edp-reprtAppCn-wordStrong">eNPS</span>和<span
            class="edp-reprtAppCn-wordStrong"
            >Pulse</span
          >的调研
        </p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq6-word3"
        v-if="info.surveyDept"
      >
        <p>并且在</p>
        <p class="edp-reprtAppCn-wordStrong">
          {{ info.surveyDept }}
        </p>

        <p>中，名列前茅</p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq6-word4">
        <p class="edp-reprtAppCn-lightBig">构筑信任</p>
        <p class="edp-reprtAppCn-lightBig">用行动诠释承诺</p>
        <p class="edp-reprtAppCn-lightBig">我们在共创中抵达新境</p>
      </div>

      <img
        src="@/public/images/app/app/sq-bg5.png"
        alt=""
        class="edp-reprtAppCn-sqBgImage"
      />
    </section>
    <!-- 6 end -->

    <!-- 7 -->
    <section class="reportAppCn-lastWord">
      <div class="reportAppCn-lastWord-op"></div>

      <div class="reportAppCn-lastWord-content">
        <p>{{ info.thisYear }}</p>
        <p>有幸与你并肩走过，</p>
        <p>一路相互陪伴、彼此扶持。</p>
        <p>所有的付出、坚持与勇气</p>
        <p>如璀璨星辰照亮了这非凡的一年</p>
      </div>

      <div class="reportAppCn-lastWord-content">
        <p>{{ info.nextYear }}</p>
        <p>我们携手前行，共创未来</p>
      </div>
    </section>
    <!-- 7 end -->

    <!-- 8 -->
    <section class="reportAppCn-last-square">
      <div class="reportAppCn-last-square-content">
        <section class="reportBlank-firstContent-year">
          <span>{{
            info.thisYear ? info.thisYear.toString().split("")[0] : ""
          }}</span>
          <span>{{
            info.thisYear ? info.thisYear.toString().split("")[1] : ""
          }}</span>
          <span>{{
            info.thisYear ? info.thisYear.toString().split("")[2] : ""
          }}</span>
          <span>{{
            info.thisYear ? info.thisYear.toString().split("")[3] : ""
          }}</span>
        </section>

        <section class="reportBlank-firstContent-title">
          <div class="reportBlank-firstContent-wordDetail">
            <p>JLR</p>
            <p style="font-weight: bold">年度报告</p>
          </div>
        </section>

        <section class="reportBlank-firstContent-remarks">
          <p>{{ info.memberName }}</p>
          <p>年度报告</p>
        </section>
      </div>

      <div class="reportAppCn-memberImageContent">
        <div class="reportAppCn-memberImageContent-image">
          <img :src="info.headImage" @error="defImg($event)" alt="" />
        </div>
      </div>

      <!--<img
        src="@/public/images/app/app/sq-bg6.png"
        alt=""
        class="edp-reprtAppCn-sqBgImage"
      />-->
    </section>
    <!-- 8 end -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  props: ["percent", "info", "cdsid"],
  name: "reportAppCn",
  data() {
    return {
      compWidth: "100%",
      compHeight: "auto",
      appLang: "cn",
    };
  },
  computed: {
    ...mapState({
      defaultHeadImage: (state) => state.login.defaultHeadImage,
    }),
  },
  methods: {
    defImg(event) {
      let img = event.srcElement;
      img.src = this.defaultHeadImage;
      img.onerror = null;
    },
    moveToOne() {
      let art1Top = (this.$refs.art1.offsetTop - 100) * this.percent;

      $("html, body").animate({ scrollTop: art1Top + "px" }, 500);
    },
    heightCalc() {
      this.compHeight = this.$refs.compCover.clientHeight * this.percent;
      this.compWidth = this.$refs.compCover.clientWidth * this.percent;
      this.$emit("resize", { height: this.compHeight, width: this.compWidth });
    },
  },
  mounted() {
    setTimeout(() => {
      this.heightCalc();
      window.addEventListener("resize", this.heightCalc);
    }, 1000);
  },
  destroyed() {
    window.removeEventListener("resize", this.heightCalc);
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-reprtAppCn-content {
  position: relative;
  width: toPc(750);
  margin: 0 auto;
  background: url("~@/public/images/app/app/bg.jpg") no-repeat center top;
  background-size: 100% auto;
  transform-origin: 0 0;
  line-height: 1.5;
  color: rgba(54, 54, 54, 1);
  font-size: toDoublePc(16);
  text-align: center;

  .edp-reprtAppCn-wordCotent {
    width: 100%;
  }
  .edp-reprtAppCn-gLine {
    display: block;
    position: relative;
    width: 100%;

    .edp-reprtAppCn-gLine-line {
      position: relative;
      width: toDoublePc(234);
      height: 1px;
      overflow: hidden;
      margin: 0 auto;
      background: #fff;
    }
    i {
      font-size: toDoublePc(20);
      line-height: 1;
      color: #fff;
    }
  }

  .edp-reprtAppCn-sqBgImage {
    display: block;
    position: absolute;
    width: auto;
    z-index: 1;
  }

  .edp-reprtAppCn-square {
    position: relative;
    width: 100%;
    z-index: 2;

    // 1
    &.ers-sq1 {
      width: 100%;
      padding: toDoublePc(60) 0 0 0;
      color: #fff;

      .ers-sq1-logoImage {
        display: block;
        width: auto;
        height: toDoublePc(68);
        margin: 0 auto;
      }
      .ers-sq1-word1 {
        padding-top: toDoublePc(76);
        font-size: toDoublePc(16);
      }
      .ers-sq1-word2 {
        padding-top: toDoublePc(4);
        font-size: toDoublePc(35);
      }
      .ers-sq1-word3 {
        margin-top: toDoublePc(-4);
      }
      .ers-sq1-largeYear {
        font-family: JLREmeric;
        font-size: toDoublePc(188);
        line-height: 1;
        letter-spacing: toDoublePc(-30);
        text-indent: toDoublePc(-30);
        padding-top: toDoublePc(22);
      }
      .edp-reprtAppCn-button1 {
        position: relative;
        display: inline-flex;
        min-width: toDoublePc(276);
        font-size: toDoublePc(22);
        padding: toDoublePc(10) toDoublePc(16);
        background: url("~@/public/images/app/one/beginButton.jpg") no-repeat
          center center;
        background-size: cover;
        align-items: center;
        justify-content: center;
        margin-top: toDoublePc(22);
        line-height: 1;
        cursor: pointer;
      }
      .ers-sq1-word4 {
        margin-top: toDoublePc(10);
        font-size: toDoublePc(13);
      }
      .edp-reprtAppCn-beginIcon {
        position: relative;
        width: toDoublePc(37);
        height: toDoublePc(21);
        overflow: hidden;
        margin: toDoublePc(10) auto;
        background: url("~@/public/images/app/one/beginIconDown.png") no-repeat
          center center;
        background-size: cover;
      }
      .edp-reprtAppCn-gLine {
        margin-top: toDoublePc(126);
      }
    }
    // 1 end

    // 2
    &.ers-sq2 {
      padding-top: toDoublePc(60);
      font-size: toDoublePc(14);
      color: rgba(54, 54, 54, 1);

      .edp-reprtAppCn-sqBgImage {
        height: toDoublePc(360);
        right: 0;
        bottom: toDoublePc(50);
      }
      .ers-sq2-spBg {
        display: inline-block;
        padding: toDoublePc(10);
        color: rgba(97, 191, 180, 1);
        font-size: toDoublePc(30);
        background: url("~@/public/images/app/one/wordsp-bg1.png") no-repeat
          center center;
        background-size: toDoublePc(110) toDoublePc(42);
        margin-left: toDoublePc(10);
        min-width: toDoublePc(110);
        text-align: center;
      }
      .ers-sq2-word3 {
        padding-top: toDoublePc(14);
      }
      .ers-sq2-word4 {
        padding-top: toDoublePc(24);
      }
      .ers-sq2-word5 {
        padding-top: toDoublePc(24);
      }
      .ers-sq2-word6 {
        padding-top: toDoublePc(27);
      }
      .edp-reprtAppCn-gLine {
        margin-top: toDoublePc(195);
      }
    }
    // 2 end

    // 3
    &.ers-sq3 {
      padding-top: toDoublePc(60);

      .edp-reprtAppCn-sqBgImage {
        height: toDoublePc(376);
        left: 0;
        bottom: toDoublePc(10);
      }

      .edp-reprtAppCn-wordStrong {
        color: #fff;
      }
      .edp-reprtAppCn-lightBig {
        color: #fff;
      }
      .ers-sq3-word2 {
        padding-top: toDoublePc(25);
      }
      .ers-sq3-word3 {
        padding-top: toDoublePc(10);
      }
      .ers-sq3-word4 {
        padding-top: toDoublePc(30);
      }
      .edp-reprtAppCn-gLine {
        margin-top: toDoublePc(206);
      }
    }
    // 3 end

    // 4
    &.ers-sq4 {
      padding-top: toDoublePc(60);

      .edp-reprtAppCn-sqBgImage {
        height: toDoublePc(254);
        right: 0;
        bottom: toDoublePc(70);
      }

      .ers-sq4-word2 {
        padding-top: toDoublePc(15);
      }
      .ers-sq4-word3 {
        padding-top: toDoublePc(15);
      }
      .ers-sq4-word4 {
        padding-top: toDoublePc(15);
      }
      .ers-sq4-word5 {
        padding-top: toDoublePc(35);
      }
      .edp-reprtAppCn-gLine {
        margin-top: toDoublePc(210);
      }
    }
    // 4 end

    // 5
    &.ers-sq5 {
      padding-top: toDoublePc(60);

      .edp-reprtAppCn-sqBgImage {
        height: toDoublePc(443);
        left: toDoublePc(-50);
        bottom: 0;
      }

      .ers-sq5-word2 {
        padding-top: toDoublePc(26);
      }
      .ers-sq5-word3 {
        margin-top: toDoublePc(11);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          display: block;
          width: toDoublePc(22);
          height: auto;
          margin-right: toDoublePc(6);
        }
      }
      .ers-sq5-word4 {
        padding-top: toDoublePc(13);
      }
      .ers-sq5-word5 {
        padding-top: toDoublePc(32);
      }
      .ers-sq5-word6 {
        padding-top: toDoublePc(32);
      }
      .edp-reprtAppCn-gLine {
        margin-top: toDoublePc(210);
      }
    }
    // 5 end

    // 6
    &.ers-sq6 {
      padding-top: toDoublePc(60);

      .edp-reprtAppCn-sqBgImage {
        height: toDoublePc(443);
        right: toDoublePc(-130);
        bottom: toDoublePc(-160);
      }

      .ers-sq6-word1 {
        line-height: 2;
      }

      .ers-sq6-word2 {
        padding-top: toDoublePc(30);
      }

      .ers-sq6-word3 {
        padding-top: toDoublePc(30);
      }

      .ers-sq6-word4 {
        padding-top: toDoublePc(30);
      }
    }
    // 6 end
  }

  .edp-reprtAppCn-wordStrong {
    font-size: toDoublePc(18);
    color: rgba(62, 136, 109, 1);
    margin: 0 toDoublePc(3);
    white-space: pre-line;
  }
  .edp-reprtAppCn-lightBig {
    font-size: toDoublePc(24);
    color: rgba(54, 54, 54, 1);
    margin: 0 toDoublePc(3);
    font-weight: bold;

    &.edp-reprtAppCn-lightBig-green {
      color: #3e886d;
    }

    .edp-reprtAppCn-lightBig-hao {
      opacity: 0.2;
      margin: 0 toDoublePc(10);
    }
  }
  .reportAppCn-lastWord {
    position: relative;
    width: toDoublePc(330);
    padding: toDoublePc(20) toDoublePc(20) 0 toDoublePc(20);
    font-size: toDoublePc(18);
    color: rgba(80, 56, 18, 1);
    margin-top: toDoublePc(180);
    left: 50%;
    transform: translateX(-50%);

    .reportAppCn-lastWord-op {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: #fff;
      opacity: 0.4;
      left: 0;
      top: 0;
    }
    .reportAppCn-lastWord-content {
      position: relative;
      width: 100%;
      text-align: center;
      z-index: 2;
      padding-bottom: toDoublePc(28);
    }
  }

  .reportAppCn-last-square {
    position: relative;
    width: 100%;
    height: toDoublePc(300);
    color: #fff;
    overflow: hidden;

    .edp-reprtAppCn-sqBgImage {
      width: 100%;
      height: auto;
      left: 0;
      top: toDoublePc(-100);
    }

    .reportAppCn-last-square-content {
      position: absolute;
      right: toDoublePc(26);
      bottom: toDoublePc(40);
      width: toDoublePc(120);
      text-align: left;
      z-index: 2;

      .reportBlank-firstContent-year {
        position: relative;
        font-size: toDoublePc(46);
        z-index: 0;
        line-height: 1;
        font-family: JLREmeric;
        font-weight: bold;
        margin-left: toDoublePc(10);

        span {
          position: relative;
          text-transform: uppercase;
          background: linear-gradient(
            133.19deg,
            rgba(255, 255, 255, 0.64) 24.02%,
            rgba(255, 255, 255, 0) 70.95%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          margin-left: toDoublePc(-10);
        }
      }

      .reportBlank-firstContent-title {
        position: relative;
        z-index: 1;
        font-size: toDoublePc(26);
        margin-top: toDoublePc(-24);

        .reportBlank-firstContent-wordDetail {
          p {
            margin-top: toDoublePc(-10);
          }
        }
      }

      .reportBlank-firstContent-remarks {
        width: 100%;
        font-size: toDoublePc(12);
        padding-top: toDoublePc(15);
      }
    }
  }

  .reportAppCn-memberImageContent {
    position: absolute;
    left: 0;
    top: toDoublePc(60);
    width: toDoublePc(188);
    height: toDoublePc(128);
    background: linear-gradient(
      90deg,
      rgba(164, 149, 122, 0.62) 11%,
      rgba(217, 217, 217, 0) 105.06%
    );
    border-radius: 0 toDoublePc(102) toDoublePc(102) 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .reportAppCn-memberImageContent-image {
      position: relative;
      width: toDoublePc(100);
      height: toDoublePc(100);
      border-radius: 100%;
      overflow: hidden;
      margin-right: toDoublePc(13);
      background: #c9c8c8;

      img {
        display: block;
        width: auto;
        height: 100%;
        position: relative;
        margin: 0 auto;
      }
    }
  }
}
</style>
