<template>
  <div
    class="edp-reprtAppEn-content"
    :style="'transform:scale(' + percent + ');'"
    ref="compCover"
  >
    <edpWaterBg
      percent="20%"
      opacity="0.4"
      itemHeight="160px"
      slot="bgContent"
    />

    <!-- 1 -->
    <section class="edp-reprtAppCn-square ers-sq1">
      <img
        src="@/src/assets/common/logo-group-white.png"
        alt=""
        class="ers-sq1-logoImage"
      />

      <div class="edp-reprtAppCn-wordCotent ers-sq1-word1">
        {{ info.memberName }}
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq1-word2">Annual Report</div>

      <div class="edp-reprtAppCn-wordCotent ers-sq1-largeYear">
        {{ info.thisYear }}
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq1-word3">
        Thank you for accompanying us in {{ info.thisYear }}.<br />
        Let’s keep moving forward together in {{ info.nextYear }}!
      </div>

      <div class="edp-reprtAppCn-button1" @click="moveToOne">
        Open your exclusive report for {{ info.thisYear }}
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq1-word4">
        * Scope of data statistics in this annual report as of the end of 2023
      </div>

      <div class="edp-reprtAppCn-beginIcon"></div>

      <section class="edp-reprtAppCn-gLine">
        <div class="edp-reprtAppCn-gLine-line"></div>
        <i class="el-icon-caret-bottom"></i>
      </section>
    </section>
    <!-- 1 end -->

    <!-- 2 -->
    <section class="edp-reprtAppCn-square ers-sq2" ref="art1">
      <div class="edp-reprtAppCn-wordCotent ers-sq2-word1">
        You’ve been with JLR for
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq2-word2">
        <span class="ers-sq2-spBg"
          >{{ utils.formatMoney(info.memberYear) }} days</span
        >

        <div v-if="info.yearInCompanyPercent >= 50">
          surpassing
          <span class="edp-reprtAppCn-wordStrong"
            >{{ info.yearInCompanyPercent || 0 }}%</span
          >
          of JLRers.
        </div>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq2-word3"
        v-if="info.salaryIncreaseStatusPage"
      >
        <p>In {{ info.thisYear }},</p>
        <p>we overcame all obstacles and kept forging ahead.</p>
        <div v-html="info.salaryIncreaseStatusPage"></div>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq2-word4"
        v-if="info.memberWork"
      >
        <p>
          Congratulations on your outstanding performance in
          {{ " " + info.thisYear }}!
        </p>

        <p>
          You were promoted to<br />
          <span class="edp-reprtAppCn-lightBig edp-reprtAppCn-lightBig-green">{{
            info.memberWork
          }}</span>
        </p>

        <p>Every effort and endeavour will not be let down.</p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq2-word5"
        v-if="info.meetingPercent || info.travelPercent || info.workingPercent"
      >
        <p>{{ info.thisYear }} was a busy year for you.</p>
        <p v-if="info.meetingPercent">
          You spent
          <span class="edp-reprtAppCn-wordStrong"
            >{{ info.meetingPercent || 0 }}%</span
          >
          of your time in meetings,
        </p>
        <p v-if="info.travelPercent">
          <span class="edp-reprtAppCn-wordStrong"
            >{{ info.travelPercent || 0 }}%</span
          >
          on business travel,
        </p>
        <p v-if="info.workingPercent">
          and
          <span class="edp-reprtAppCn-wordStrong"
            >{{ " " + (info.workingPercent || 0) }}%</span
          >
          on daily work.
        </p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq2-word5" v-if="info.tagPage">
        <p>You deserve the title of</p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq2-word6" v-if="info.tagPage">
        <span class="edp-reprtAppCn-lightBig">
          {{ info.tagPage }}
        </span>
      </div>

      <section class="edp-reprtAppCn-gLine">
        <div class="edp-reprtAppCn-gLine-line"></div>
        <i class="el-icon-caret-bottom"></i>
      </section>

      <img
        src="@/public/images/app/app/sq-bg1.png"
        alt=""
        class="edp-reprtAppCn-sqBgImage"
      />
    </section>
    <!-- 2 end -->

    <!-- 3 -->
    <section class="edp-reprtAppCn-square ers-sq3" v-if="info.meetingNum">
      <div class="edp-reprtAppCn-wordCotent ers-sq3-word1">
        <p>This year,</p>

        <p v-if="info.meetingNum">
          you attended
          <span class="edp-reprtAppCn-wordStrong">{{
            info.meetingNum ? utils.formatMoney(info.meetingNum) : 0
          }}</span>
          meetings,
        </p>

        <p v-if="info.meetingPeopleNum">
          brainstorming with
          <span class="edp-reprtAppCn-wordStrong">{{
            info.meetingPeopleNum ? utils.formatMoney(info.meetingPeopleNum) : 0
          }}</span
          >people.
        </p>

        <p v-if="info.memberDay && info.memberTime">
          On<span class="edp-reprtAppCn-wordStrong">{{ info.memberDay }}</span>
          you had a meeting until
          <span class="edp-reprtAppCn-wordStrong">{{ info.memberTime }}.</span>
        </p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq3-word2"
        v-if="info.meetingMembers"
      >
        <p>
          This year, you and the following colleagues have had far too many busy
          meetings.
        </p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq3-word3"
        v-if="info.meetingMembers"
      >
        <span class="edp-reprtAppCn-wordStrong">{{ info.meetingMembers }}</span>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq3-word2"
        v-if="info.meetingMembers"
      >
        <p>The title of “World Meeting Champion” is exclusively yours!</p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq3-word4">
        <p>
          <span class="edp-reprtAppCn-lightBig"
            >Ideas collide to spark innovation,
          </span>
        </p>
        <p>
          <span class="edp-reprtAppCn-lightBig"
            >while communication and collaboration can solve all problems.
          </span>
        </p>
      </div>

      <section class="edp-reprtAppCn-gLine">
        <div class="edp-reprtAppCn-gLine-line"></div>
        <i class="el-icon-caret-bottom"></i>
      </section>

      <img
        src="@/public/images/app/app/sq-bg2.png"
        alt=""
        class="edp-reprtAppCn-sqBgImage"
      />
    </section>
    <!-- 3 end -->

    <!-- 4 -->
    <section class="edp-reprtAppCn-square ers-sq4" v-if="info.travelDays">
      <div class="edp-reprtAppCn-wordCotent ers-sq4-word1">
        <p>In {{ info.thisYear }}，you finally took off your mask,</p>
        <p>
          and packed enthusiasm into
          <span class="edp-reprtAppCn-wordStrong">{{ info.travelDays }}</span
          >days and nights of travel.
        </p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq4-word2"
        v-if="info.travelCitysPage"
      >
        <p>Your business trips were spread across</p>
        <p class="edp-reprtAppCn-wordStrong">{{ info.travelCitysPage }}</p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq4-word3"
        v-if="info.travelFarCitysPage"
      >
        <p>And even further afield to</p>
        <p class="edp-reprtAppCn-wordStrong">{{ info.travelFarCitysPage }}</p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq4-word4">
        <p>
          Blending work and adventure, you embraced achievement and
          opportunities.
        </p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq4-word5">
        <span class="edp-reprtAppCn-lightBig">
          <p>Marching triumphantly forward, undaunted by distance.</p>
          <p>You seized the moment, both day and night.</p></span
        >
      </div>

      <section class="edp-reprtAppCn-gLine">
        <div class="edp-reprtAppCn-gLine-line"></div>
        <i class="el-icon-caret-bottom"></i>
      </section>

      <img
        src="@/public/images/app/app/sq-bg3.png"
        alt=""
        class="edp-reprtAppCn-sqBgImage"
      />
    </section>
    <!-- 4 end -->

    <!-- 5 -->
    <section
      class="edp-reprtAppCn-square ers-sq5"
      v-if="info.edcActivityNum || info.csrNum || info.trainNum"
    >
      <div class="edp-reprtAppCn-wordCotent ers-sq5-word1">
        <p>In {{ info.thisYear }}, you attended:</p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq5-word2"
        v-if="info.edcActivityNum"
      >
        <p>
          <span class="edp-reprtAppCn-wordStrong">{{
            info.edcActivityNum || 0
          }}</span
          >EDC Activities
        </p>

        <p v-if="info.favoriteActivity">
          <span class="edp-reprtAppCn-wordStrong">{{
            info.favoriteActivity
          }}</span
          >was your favorite
        </p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq5-word3" v-if="info.csrNum">
        <img src="@/public/images/app/app/active.png" alt="" />
        <span class="edp-reprtAppCn-wordStrong">{{ info.csrNum }}</span
        >CSR Activities
      </div>

      <p v-if="info.csrNum">Thumbs up for your kindness and loving support!</p>

      <div class="edp-reprtAppCn-wordCotent ers-sq5-word4" v-if="info.trainNum">
        <p>
          <span class="edp-reprtAppCn-wordStrong">{{ info.trainNum }}</span
          >Training Sessions
        </p>
        <p v-if="info.interestTopic">
          <span class="edp-reprtAppCn-wordStrong">{{ info.interestTopic }}</span
          >was your favorite topic
        </p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq5-word5"
        v-if="info.position == 1"
      >
        <p>
          Meanwhile, we’d like to thank you for your contribution to JLR as an
          internal <span class="edp-reprtAppCn-wordStrong">trainer</span>!
        </p>
        <p>Your insights have resonated strongly with our colleagues.</p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq5-word6">
        <p><span class="edp-reprtAppCn-lightBig">A thousand words</span></p>
        <p>
          <span class="edp-reprtAppCn-lightBig"
            >cannot adequately describe the value of having you with us.
          </span>
        </p>
      </div>

      <section class="edp-reprtAppCn-gLine">
        <div class="edp-reprtAppCn-gLine-line"></div>
        <i class="el-icon-caret-bottom"></i>
      </section>

      <img
        src="@/public/images/app/app/sq-bg4.png"
        alt=""
        class="edp-reprtAppCn-sqBgImage"
      />
    </section>
    <!-- 5 end -->

    <!-- 6 -->
    <section
      class="edp-reprtAppCn-square ers-sq6"
      v-if="
        info.dept ||
        info.surveyDept ||
        info.score ||
        info.carModelPage ||
        info.recommendedNum
      "
    >
      <div class="edp-reprtAppCn-wordCotent ers-sq6-word1">
        <p>In {{ info.thisYear }},</p>

        <p v-if="info.score">
          you received a total of
          <span class="edp-reprtAppCn-wordStrong">{{
            utils.formatMoney(info.score)
          }}</span
          >JLR points.
        </p>

        <p v-if="info.carModelPage">
          As an owner of a
          <span class="edp-reprtAppCn-wordStrong">{{ info.carModelPage }}</span
          >, you travelled upwards and onwards.
        </p>

        <p v-if="info.recommendedNum">
          This year, you recommended
          <span class="edp-reprtAppCn-wordStrong">{{
            info.recommendedNum || 0
          }}</span
          >candidates to join the JLR family.
        </p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq6-word2"
        v-if="info.dept && info.surveyDept"
      >
        <p>
          Your Department –
          <span class="edp-reprtAppCn-wordStrong">{{ info.dept }}</span>
        </p>

        <p>
          actively participated in the
          <span class="edp-reprtAppCn-wordStrong">eNPS</span>and<span
            class="edp-reprtAppCn-wordStrong"
            >Pulse</span
          >surveys,
        </p>
      </div>

      <div
        class="edp-reprtAppCn-wordCotent ers-sq6-word3"
        v-if="info.surveyDept"
      >
        <p>and ranked at the top of the company in the</p>
        <p class="edp-reprtAppCn-wordStrong">
          {{ info.surveyDept }}
        </p>
      </div>

      <div class="edp-reprtAppCn-wordCotent ers-sq6-word4">
        <p class="edp-reprtAppCn-lightBig">Let’s build trust,</p>
        <p class="edp-reprtAppCn-lightBig">
          demonstrate commitment through actions,
        </p>
        <p class="edp-reprtAppCn-lightBig">
          and reach new heights through co-creation.
        </p>
      </div>

      <img
        src="@/public/images/app/app/sq-bg5.png"
        alt=""
        class="edp-reprtAppCn-sqBgImage"
      />
    </section>
    <!-- 6 end -->

    <!-- 7 -->
    <section class="reportAppCn-lastWord">
      <div class="reportAppCn-lastWord-op"></div>

      <div class="reportAppCn-lastWord-content">
        <p>In {{ info.thisYear }}</p>
        <p>we’ve had the honour of</p>
        <p>journeying together with you,</p>
        <p>accompanying and</p>
        <p>supporting each other.</p>
        <p>Your dedication, persistence,</p>
        <p>and courage</p>
        <p>have helped to light up</p>
        <p>an exceptional year.</p>
      </div>

      <div class="reportAppCn-lastWord-content">
        <p>In {{ info.nextYear }}</p>
        <p>let’s embrace the future,</p>
        <p>and move forward together!</p>
      </div>
    </section>
    <!-- 7 end -->

    <!-- 8 -->
    <section class="reportAppCn-last-square">
      <div class="reportAppCn-last-square-content">
        <section class="reportBlank-firstContent-year">
          <span>{{
            info.thisYear ? info.thisYear.toString().split("")[0] : ""
          }}</span>
          <span>{{
            info.thisYear ? info.thisYear.toString().split("")[1] : ""
          }}</span>
          <span>{{
            info.thisYear ? info.thisYear.toString().split("")[2] : ""
          }}</span>
          <span>{{
            info.thisYear ? info.thisYear.toString().split("")[3] : ""
          }}</span>
        </section>

        <section class="reportBlank-firstContent-title">
          <div class="reportBlank-firstContent-wordDetail">
            <p>JLR</p>
            <p style="font-weight: bold">Annual</p>
            <p style="font-weight: bold">Report</p>
          </div>
        </section>

        <section class="reportBlank-firstContent-remarks">
          <p>{{ info.memberName }}</p>
          <p>Annual<br />Report</p>
        </section>
      </div>

      <div class="reportAppCn-memberImageContent">
        <div class="reportAppCn-memberImageContent-image">
          <img :src="info.headImage" @error="defImg($event)" alt="" />
        </div>
      </div>

      <!--<img
        src="@/public/images/app/app/sq-bg6.png"
        alt=""
        class="edp-reprtAppCn-sqBgImage"
      />-->
    </section>
    <!-- 8 end -->
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";

export default {
  props: ["percent", "info", "cdsid"],
  name: "reportAppEn",
  data() {
    return {
      compWidth: "100%",
      compHeight: "auto",
      appLang: "cn",
    };
  },
  computed: {
    ...mapState({
      defaultHeadImage: (state) => state.login.defaultHeadImage,
    }),
  },
  methods: {
    defImg(event) {
      let img = event.srcElement;
      img.src = this.defaultHeadImage;
      img.onerror = null;
    },
    moveToOne() {
      let art1Top = (this.$refs.art1.offsetTop - 100) * this.percent;

      $("html, body").animate({ scrollTop: art1Top + "px" }, 500);
    },
    heightCalc() {
      this.compHeight = this.$refs.compCover.clientHeight * this.percent;
      this.compWidth = this.$refs.compCover.clientWidth * this.percent;
      this.$emit("resize", { height: this.compHeight, width: this.compWidth });
    },
  },
  mounted() {
    setTimeout(() => {
      this.heightCalc();
      window.addEventListener("resize", this.heightCalc);
    }, 1000);
  },
  destroyed() {
    window.removeEventListener("resize", this.heightCalc);
  },
};
</script>

<style lang="scss">
@import "@/src/styles/variable.scss";

.edp-reprtAppEn-content {
  position: relative;
  width: toPc(750);
  margin: 0 auto;
  background: url("~@/public/images/app/app/bg.jpg") no-repeat center top;
  background-size: 100% auto;
  transform-origin: 0 0;
  line-height: 1.5;
  color: rgba(54, 54, 54, 1);
  font-size: toDoublePc(16);
  text-align: center;

  .edp-reprtAppCn-wordCotent {
    width: 100%;
  }
  .edp-reprtAppCn-gLine {
    display: block;
    position: relative;
    width: 100%;

    .edp-reprtAppCn-gLine-line {
      position: relative;
      width: toDoublePc(234);
      height: 1px;
      overflow: hidden;
      margin: 0 auto;
      background: #fff;
    }
    i {
      font-size: toDoublePc(20);
      line-height: 1;
      color: #fff;
    }
  }

  .edp-reprtAppCn-sqBgImage {
    display: block;
    position: absolute;
    width: auto;
    z-index: 1;
  }

  .edp-reprtAppCn-square {
    position: relative;
    width: 100%;
    z-index: 2;
    padding: 0 toDoublePc(20);

    // 1
    &.ers-sq1 {
      width: 100%;
      padding: toDoublePc(60) 0 0 0;
      color: #fff;

      .ers-sq1-logoImage {
        display: block;
        width: auto;
        height: toDoublePc(68);
        margin: 0 auto;
      }
      .ers-sq1-word1 {
        padding-top: toDoublePc(76);
        font-size: toDoublePc(16);
      }
      .ers-sq1-word2 {
        padding-top: toDoublePc(4);
        font-size: toDoublePc(35);
      }
      .ers-sq1-word3 {
        margin-top: toDoublePc(-4);
      }
      .ers-sq1-largeYear {
        font-family: JLREmeric;
        font-size: toDoublePc(188);
        line-height: 1;
        letter-spacing: toDoublePc(-30);
        text-indent: toDoublePc(-30);
        padding-top: toDoublePc(22);
      }
      .edp-reprtAppCn-button1 {
        position: relative;
        display: inline-flex;
        min-width: toDoublePc(276);
        font-size: toDoublePc(16);
        padding: toDoublePc(10) 0;
        background: url("~@/public/images/app/one/beginButton.jpg") no-repeat
          center center;
        background-size: cover;
        align-items: center;
        justify-content: center;
        margin-top: toDoublePc(22);
        line-height: 1;
        cursor: pointer;
      }
      .ers-sq1-word4 {
        margin-top: toDoublePc(10);
        font-size: toDoublePc(13);
      }
      .edp-reprtAppCn-beginIcon {
        position: relative;
        width: toDoublePc(37);
        height: toDoublePc(21);
        overflow: hidden;
        margin: toDoublePc(10) auto;
        background: url("~@/public/images/app/one/beginIconDown.png") no-repeat
          center center;
        background-size: cover;
      }
      .edp-reprtAppCn-gLine {
        margin-top: toDoublePc(126);
      }
    }
    // 1 end

    // 2
    &.ers-sq2 {
      padding-top: toDoublePc(60);
      font-size: toDoublePc(14);
      color: rgba(54, 54, 54, 1);

      .edp-reprtAppCn-sqBgImage {
        height: toDoublePc(360);
        right: 0;
        bottom: toDoublePc(50);
      }
      .ers-sq2-spBg {
        display: inline-block;
        padding: toDoublePc(10);
        color: rgba(97, 191, 180, 1);
        font-size: toDoublePc(30);
        background: url("~@/public/images/app/one/wordsp-bg1.png") no-repeat
          center center;
        background-size: toDoublePc(110) toDoublePc(42);
        margin-left: toDoublePc(10);
        min-width: toDoublePc(110);
        text-align: center;
      }
      .ers-sq2-word3 {
        padding-top: toDoublePc(14);
      }
      .ers-sq2-word4 {
        padding-top: toDoublePc(24);
      }
      .ers-sq2-word5 {
        padding-top: toDoublePc(24);
      }
      .ers-sq2-word6 {
        padding-top: toDoublePc(27);
      }
      .edp-reprtAppCn-gLine {
        margin-top: toDoublePc(195);
      }
    }
    // 2 end

    // 3
    &.ers-sq3 {
      padding-top: toDoublePc(60);

      .edp-reprtAppCn-sqBgImage {
        height: toDoublePc(376);
        left: 0;
        bottom: toDoublePc(10);
      }

      .edp-reprtAppCn-wordStrong {
        color: #fff;
      }
      .edp-reprtAppCn-lightBig {
        color: #fff;
      }
      .ers-sq3-word2 {
        padding-top: toDoublePc(25);
      }
      .ers-sq3-word3 {
        padding-top: toDoublePc(10);
      }
      .ers-sq3-word4 {
        padding-top: toDoublePc(30);
      }
      .edp-reprtAppCn-gLine {
        margin-top: toDoublePc(206);
      }
    }
    // 3 end

    // 4
    &.ers-sq4 {
      padding-top: toDoublePc(60);

      .edp-reprtAppCn-sqBgImage {
        height: toDoublePc(254);
        right: 0;
        bottom: toDoublePc(70);
      }

      .ers-sq4-word2 {
        padding-top: toDoublePc(15);
      }
      .ers-sq4-word3 {
        padding-top: toDoublePc(15);
      }
      .ers-sq4-word4 {
        padding-top: toDoublePc(15);
      }
      .ers-sq4-word5 {
        padding-top: toDoublePc(35);
      }
      .edp-reprtAppCn-gLine {
        margin-top: toDoublePc(210);
      }
    }
    // 4 end

    // 5
    &.ers-sq5 {
      padding-top: toDoublePc(60);

      .edp-reprtAppCn-sqBgImage {
        height: toDoublePc(443);
        left: toDoublePc(-50);
        bottom: 0;
      }

      .ers-sq5-word2 {
        padding-top: toDoublePc(26);
      }
      .ers-sq5-word3 {
        margin-top: toDoublePc(11);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          display: block;
          width: toDoublePc(22);
          height: auto;
          margin-right: toDoublePc(6);
        }
      }
      .ers-sq5-word4 {
        padding-top: toDoublePc(13);
      }
      .ers-sq5-word5 {
        padding-top: toDoublePc(32);
      }
      .ers-sq5-word6 {
        padding-top: toDoublePc(32);
      }
      .edp-reprtAppCn-gLine {
        margin-top: toDoublePc(210);
      }
    }
    // 5 end

    // 6
    &.ers-sq6 {
      padding-top: toDoublePc(60);

      .edp-reprtAppCn-sqBgImage {
        height: toDoublePc(443);
        right: toDoublePc(-130);
        bottom: toDoublePc(-160);
      }

      .ers-sq6-word1 {
        line-height: 2;
      }

      .ers-sq6-word2 {
        padding-top: toDoublePc(30);
      }

      .ers-sq6-word3 {
        padding-top: toDoublePc(30);
      }

      .ers-sq6-word4 {
        padding-top: toDoublePc(30);
      }
    }
    // 6 end
  }

  .edp-reprtAppCn-wordStrong {
    font-size: toDoublePc(18);
    color: rgba(62, 136, 109, 1);
    margin: 0 toDoublePc(3);
    white-space: pre-line;
  }
  .edp-reprtAppCn-lightBig {
    font-size: toDoublePc(22);
    color: rgba(54, 54, 54, 1);
    margin: 0 toDoublePc(3);

    &.edp-reprtAppCn-lightBig-green {
      color: #3e886d;
    }

    .edp-reprtAppCn-lightBig-hao {
      opacity: 0.2;
      margin: 0 toDoublePc(10);
    }
  }
  .reportAppCn-lastWord {
    position: relative;
    width: toDoublePc(330);
    padding: toDoublePc(20) toDoublePc(20) 0 toDoublePc(20);
    font-size: toDoublePc(18);
    color: rgba(80, 56, 18, 1);
    margin-top: toDoublePc(180);
    left: 50%;
    transform: translateX(-50%);

    .reportAppCn-lastWord-op {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 1;
      background: #fff;
      opacity: 0.4;
      left: 0;
      top: 0;
    }
    .reportAppCn-lastWord-content {
      position: relative;
      width: 100%;
      text-align: center;
      z-index: 2;
      padding-bottom: toDoublePc(28);
    }
  }

  .reportAppCn-last-square {
    position: relative;
    width: 100%;
    height: toDoublePc(300);
    color: #fff;
    overflow: hidden;

    .edp-reprtAppCn-sqBgImage {
      width: 100%;
      height: auto;
      left: 0;
      top: toDoublePc(-100);
    }

    .reportAppCn-last-square-content {
      position: absolute;
      right: toDoublePc(26);
      bottom: toDoublePc(40);
      width: toDoublePc(120);
      text-align: left;
      z-index: 2;

      .reportBlank-firstContent-year {
        position: relative;
        font-size: toDoublePc(46);
        z-index: 0;
        line-height: 1;
        font-family: JLREmeric;
        font-weight: bold;
        margin-left: toDoublePc(10);

        span {
          position: relative;
          text-transform: uppercase;
          background: linear-gradient(
            133.19deg,
            rgba(255, 255, 255, 0.64) 24.02%,
            rgba(255, 255, 255, 0) 70.95%
          );
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          background-clip: text;
          text-fill-color: transparent;
          margin-left: toDoublePc(-10);
        }
      }

      .reportBlank-firstContent-title {
        position: relative;
        z-index: 1;
        font-size: toDoublePc(26);
        margin-top: toDoublePc(-24);

        .reportBlank-firstContent-wordDetail {
          p {
            margin-top: toDoublePc(-13);
          }
        }
      }

      .reportBlank-firstContent-remarks {
        width: 100%;
        font-size: toDoublePc(12);
        padding-top: toDoublePc(15);
      }
    }
  }

  .reportAppCn-memberImageContent {
    position: absolute;
    left: 0;
    top: toDoublePc(60);
    width: toDoublePc(188);
    height: toDoublePc(128);
    background: linear-gradient(
      90deg,
      rgba(164, 149, 122, 0.62) 11%,
      rgba(217, 217, 217, 0) 105.06%
    );
    border-radius: 0 toDoublePc(102) toDoublePc(102) 0;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    .reportAppCn-memberImageContent-image {
      position: relative;
      width: toDoublePc(100);
      height: toDoublePc(100);
      border-radius: 100%;
      overflow: hidden;
      margin-right: toDoublePc(13);
      background: #c9c8c8;

      img {
        display: block;
        width: auto;
        height: 100%;
        position: relative;
        margin: 0 auto;
      }
    }
  }
}
</style>
